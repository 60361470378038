import { useDataFormat } from 'Simple/Data.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let person_id = useDataFormat({
    viewPath: props.viewPath,
    context: 'auth',
    path: 'access_token_data.vaxiom_person_id',
    format: value => value && parseInt(value, 10),
  })

  let user_id = useDataFormat({
    viewPath: props.viewPath,
    context: 'auth',
    path: 'access_token_data.user_id',
  })

  return useMemo(() => {
    if (!person_id) return data

    return {
      ...data,
      person_id,
      user_id,
      person_profile_id: person_id,
      person_user_id: user_id,
    }
  }, [person_id, user_id, data])
}
