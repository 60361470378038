import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import {
  generateSignedUrl,
  getS3ObjectUrl,
  useAwsCredentials,
  useAwsS3Client,
} from 'Data/s3.js'
import { useDataValue } from 'Simple/Data.js'
import View from './view.js'
import React, { useState, useEffect } from 'react'
import { blobToBase64 } from 'Logic/base64.js'

export default function Logic(props) {
  let file_id = useDataValue({
    context: 'logo',
    path: 'file_id',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  let s3 = useAwsS3Client({ viewPath: props.viewPath })
  let getAwsCredentials = useAwsCredentials(props)

  let [url, setUrl] = useState(null) // State to hold the URL

  useEffect(() => {
    let cancel = false

    async function run() {
      let url
      if (file_id) {
        let awsCredentials = await getAwsCredentials()
        let s3url = await generateSignedUrl(
          s3,
          getS3ObjectUrl({
            bucket: awsCredentials.legacy_storage_bucket_name,
            key: `logoimg/${file_id}`,
            region: awsCredentials.region,
          })
        )
        // Check that file exists, mostly for other envs outside of production
        // but otherwise the PDF generation will fail
        let exists = await fetch(s3url, { method: 'GET' })

        if (cancel) return

        if (exists.ok) {
          try {
            let base64img = await blobToBase64(await exists.blob())

            if (base64img.startsWith('data:')) {
              url = base64img
            }
          } catch (error) {
            console.error('Error converting blob to base64:', error)
          }
        }
      }

      if (!cancel) {
        setUrl(url) // Update the URL state

        if (!url) {
          setFlowTo(normalizePath(props.viewPath, '../No'))
        }
      }
    }
    run()

    return () => {
      cancel = true
    }
  }, [file_id]) // Add dependencies to avoid stale closures

  return <View {...props} url={url} /> // Pass the URL to the View component as a prop
}
