import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let result = data.map(item => {
      let logo =
        item.logo[0] ||
        item.parent.logo[0] ||
        item.parent?.parent?.logo[0] ||
        null

      return logo
    })

    return result[0]
  }, [data]) //eslint-disable-line
}
