// this is an autogenerated file from DesignSystem/BrandLogo/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_BrandLogo($id: numeric!) {
  vaxiom_sys_organizations(where: { id: { _eq: $id } }) {
    _id
    logo {
      id
      file_id
    }
    parent {
      _id
      logo {
        id
        file_id
      }
      parent {
        _id
        logo {
          id
          file_id
        }
      }
    }
  }
}

`