import { useDataValue, useDataFormat } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let user_id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'user_id',
  })

  let user_ids = useDataFormat({
    viewPath: props.viewPath,
    context: 'account',
    path: 'persons',
    format: value => value.map(item => item?.public_user?.id).filter(Boolean),
  })

  return {
    variables: {
      user_ids: [...user_ids, user_id],
    },
    pause: !user_id,
  }
}
