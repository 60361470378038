import { useDataValue, useDataChange } from 'Simple/Data.js'
import React, { useEffect } from 'react'
import View from './view.js'

export default function Logic(props) {
  let theme = useDataValue({
    context: 'account',
    path: 'feature_flags.theme',
    viewPath: props.viewPath,
  })

  let currentTheme = useDataValue({
    viewPath: props.viewPath,
    context: 'design_tokens_theme',
  })

  let themeChange = useDataChange({
    viewPath: props.viewPath,
    context: 'design_tokens_theme',
  })

  useEffect(() => {
    if (!theme || theme === 'true' || currentTheme === theme) return

    themeChange(theme)
  }, [theme]) //eslint-disable-line

  return <View viewPath={props.viewPath} />
}
