// this is an autogenerated file from App/Account/Content/Content/Home/Practice/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Content_Home_Practice($id: numeric!) {
  vaxiom_sys_organizations(where: { id: { _eq: $id } }) {
    _id
    name
    parent {
      _id
      name
    }
  }
}

`