import { useDataFormat } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let id = useDataFormat({
    viewPath: props.viewPath,
    context: 'auth',
    path: 'access_token_data.vaxiom_person_id',
    format: value => value && parseInt(value, 10),
  })

  let user_id = useDataFormat({
    viewPath: props.viewPath,
    context: 'auth',
    path: 'access_token_data.user_id',
  })

  return {
    variables: {
      id,
      user_id,
    },
    pause: !id,
  }
}
