import { validate as isValidEmail } from 'email-validator'
import isPastDate from 'date-fns/isPast'
import isValidDate from 'date-fns/isValid'
import parseISODate from 'date-fns/parseISO'
import phoneRegex from 'phone-regex'
import { textToNumber } from './format.js'
import {
  patientHasGeneralDentistOptionsSet as patientHasGeneralDentistOptions,
  patientAntibioticsOptionsSet as patientAntibioticsOptions,
  patientIsInPainOptionsSet as patientIsInPainOptions,
  patientProblemPreviousProcedureOptionsSet as patientProblemPreviousProcedureOptions,
  patientJawPainOptionsSet as patientJawPainOptions,
  patientGeneralHealthOptionsSet as patientGeneralHealthOptions,
  patientDentalHealthOptionsSet as patientDentalHealthOptions,
  patientLikeSmileOptionsSet as patientLikeSmileOptions,
  patientGumsOptionsSet as patientGumsOptions,
  patientPeriodontalDiseaseOptionsSet as patientPeriodontalDiseaseOptions,
  patientToothbrushOptionsSet as patientToothbrushOptions,
  patientReactionsOptionsSet as patientReactionsOptions,
  patientRelativesCrowdingOptionsSet as patientRelativesCrowdingOptions,
  patientClickingPainOptionsSet as patientClickingPainOptions,
  patientHasPersonalPhysicianOptionsSet as patientHasPersonalPhysicianOptions,
  patientActiveCareOptionsSet as patientActiveCareOptions,
  patientColdOptionsSet as patientColdOptions,
  patientCancerMedicationsOptionsSet as patientCancerMedicationsOptions,
  patientHabitsOptionsSet as patientHabitsOptions,
  patientTobaccoOptionsSet as patientTobaccoOptions,
  patientBirthControlOptionsSet as patientBirthControlOptions,
  patientPregnantOptionsSet as patientPregnantOptions,
  patientNursingOptionsSet as patientNursingOptions,
  patientMedicalConditionsOptionsSet as patientMedicalConditionsOptions,
  patientAllergiesOptionsSet as patientAllergiesOptions,
  patientGenderOptions,
} from './enums'

export function email(value) {
  return isValidEmail(value)
}
// https://stackoverflow.com/a/2385967/1562732
export function name(value) {
  return (
    value &&
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð][a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u.test(
      value.trim()
    )
  )
}

export function trueFalse(value) {
  return value === true || value === false
}

export function birthday(value) {
  return date(value) && isPastDate(parseISODate(value))
}

export function pastDate(value) {
  return date(value) && isPastDate(parseISODate(value))
}

export function date(value) {
  return isValidDate(parseISODate(value))
}

export function phoneNumber(value) {
  return phoneRegex({ exact: true }).test(value)
}

export function textInput(value) {
  return value && value.length >= 2
}

export function street(value) {
  return textInput(value)
}

export function city(value) {
  return textInput(value)
}

export function state(value) {
  return value && value.length === 2
}

export function zip(value) {
  return /^(?!.*[DFIOQU])[A-VXY]\d[A-Z] ?\d[A-Z]\d$|^\d{5}(?:[-\s]\d{4})?$/i.test(
    value
  )
}

export function address({
  addressStreet,
  addressCity,
  addressState,
  addressZip,
}) {
  return (
    street(addressStreet) &&
    city(addressCity) &&
    state(addressState) &&
    zip(addressZip)
  )
}

export function events(value) {
  return value.length > 0
}

export function npi(value) {
  return /^\d{10}$/.test(value)
}

export function signupCode(value) {
  return /^\d{6}$/.test(value)
}

export function website(value) {
  if (!value) return true
  return value.includes('.')
}

export function password(value) {
  return (
    password_eight_chars_or_more(value) &&
    password_one_lowercase_letter(value) &&
    password_one_uppercase_letter(value) &&
    password_one_number(value) &&
    password_one_special_char(value)
  )
}

export function password_eight_chars_or_more(value) {
  return value.length >= 8
}
let LOWERCASE_LETTERS = /[a-z]/
export function password_one_lowercase_letter(value) {
  return LOWERCASE_LETTERS.test(value)
}
let UPPERCASE_LETTERS = /[A-Z]/
export function password_one_uppercase_letter(value) {
  return UPPERCASE_LETTERS.test(value)
}
let NUMBERS = /[0-9]/
export function password_one_number(value) {
  return NUMBERS.test(value)
}
let SPECIAL_CHARACTERS = /[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`]/
export function password_one_special_char(value) {
  return SPECIAL_CHARACTERS.test(value)
}

let UUID =
  /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
export function uuid(value) {
  return value && UUID.test(value)
}

let SOCIAL_SECURITY_NUMBER = /^\d{3}-\d{2}-\d{4}$/
export function social_security_number(value) {
  return value && SOCIAL_SECURITY_NUMBER.test(value)
}

export function numberZeroOrPositive(value) {
  return Number.isFinite(value) && value >= 0
}

export function validateCurrency(value) {
  return textToNumber(value) > 0
}

export function numberPositive(value) {
  return Number.isFinite(value) && value > 0
}

export function patientGeneralDentist(value) {
  return (
    value.has_general_dentist === false ||
    (value.has_general_dentist === true &&
      name(value.general_dentist_first_name) &&
      patientGeneralDentistPhone(value.general_dentist_phone) &&
      patientGeneralDentistLastVisit(value.last_dental_care))
  )
}

export function patientHasGeneralDentist(value) {
  return simpleChoice(value, patientHasGeneralDentistOptions)
}

export function patientGeneralDentistPhone(value) {
  return !value || phoneNumber(value)
}

export function patientGeneralDentistLastVisit(value) {
  return !value || pastDate(value)
}

export function patientAntibiotics(value) {
  return simpleChoice(value, patientAntibioticsOptions)
}

export function patientIsInPain(value) {
  return simpleChoice(value, patientIsInPainOptions)
}

export function patientFirstTooth(value) {
  return !value || (!isNaN(value) && value.length <= 2)
}

export function patientProblemPreviousProcedure(value) {
  return simpleChoice(value, patientProblemPreviousProcedureOptions)
}

export function patientJawPain(value) {
  return simpleChoice(value, patientJawPainOptions)
}

export function patientGeneralHealth(value) {
  return simpleChoice(value, patientGeneralHealthOptions)
}

export function patientDentalHealth(value) {
  return simpleChoice(value, patientDentalHealthOptions)
}

export function patientLikeSmile(value) {
  return simpleChoice(value, patientLikeSmileOptions)
}

export function patientGums(value) {
  return simpleChoice(value, patientGumsOptions)
}

export function patientPeriodontalDisease(value) {
  return simpleChoice(value, patientPeriodontalDiseaseOptions)
}

export function patientWeekFloss(value) {
  let numb = parseInt(value)
  return value && value.length <= 2 && numberZeroOrPositive(numb) && numb < 100
}

export function patientDayBrush(value) {
  let numb = parseInt(value)
  return value && value.length <= 2 && numberZeroOrPositive(numb) && numb < 100
}

export function patientToothbrush(value) {
  return simpleChoice(value, patientToothbrushOptions)
}

export function patientReactions(value) {
  return simpleChoice(value, patientReactionsOptions)
}

export function patientRelativesCrowding(values) {
  let selectedValues = Object.keys(values).filter(key => values[key] === true)
  return multipleChoice(selectedValues, patientRelativesCrowdingOptions)
}

export function patientClickingPain(value) {
  return simpleChoice(value, patientClickingPainOptions)
}

export function patientPersonalPhysician(value) {
  return (
    value.has_physician === false ||
    (value.has_physician === true &&
      name(value.under_care_physician) &&
      patientPersonalPhysicianPhone(value.under_care_physician_phone) &&
      patientActiveCare(value.physician_active_care) &&
      patientPhysicianLastVisit(value.personal_physician_last_visit))
  )
}

export function patientHasPersonalPhysician(value) {
  return simpleChoice(value, patientHasPersonalPhysicianOptions)
}

export function patientPersonalPhysicianPhone(value) {
  return !value || phoneNumber(value)
}

export function patientActiveCare(value) {
  return simpleChoice(value, patientActiveCareOptions)
}

export function patientPhysicianLastVisit(value) {
  return !value || pastDate(value)
}

export function patientCold(value) {
  return simpleChoice(value, patientColdOptions)
}

export function patientCancerMedications(value) {
  return simpleChoice(value, patientCancerMedicationsOptions)
}

export function patientHabits(values) {
  let selectedValues = Object.keys(values).filter(key => values[key] === true)
  return multipleChoice(selectedValues, patientHabitsOptions)
}

export function patientTobacco(value) {
  return simpleChoice(value, patientTobaccoOptions)
}

export function patientBirthControl(value) {
  return simpleChoice(value, patientBirthControlOptions)
}

export function patientPregnant(value) {
  return simpleChoice(value, patientPregnantOptions)
}

export function patientPregnancyWeek(value) {
  return !value || (numberPositive(value) && value < 45)
}

export function patientNursing(value) {
  return simpleChoice(value, patientNursingOptions)
}

export function patientMedicalConditions(values) {
  let selectedValues = Object.keys(values).filter(key => values[key] === true)
  return multipleChoice(selectedValues, patientMedicalConditionsOptions)
}

export function medication(value) {
  return textInput(value)
}

export function patientMedications(values) {
  return values.length < 20
}

export function patientAllergies(values) {
  return multipleChoiceWithCustom(values, patientAllergiesOptions)
}

export function accountNumber(value) {
  return value && value.length >= 4 && value.length <= 17
}

export function routingNumber(value) {
  return value && /^\d{0,10}$/.test(value)
}

export function patientGenderValidate(value) {
  return simpleChoice(
    value,
    patientGenderOptions.map(o => o.value)
  )
}

let IS_SIGNATURE_REQUEST_CODE = /^[0-9a-fA-F]{6}/i
export function signatureRequestCode(value) {
  return value && value.length === 6 && IS_SIGNATURE_REQUEST_CODE.test(value)
}

export function signedDocument(value) {
  return !value.some(section => !sectionDocument(section))
}

export function sectionDocument(value) {
  return !value.elements.some(
    e =>
      e.required &&
      ((e.type === 'signature' && !e.signature.data) ||
        (e.type === 'checkbox' && !e.value) ||
        (e.type === 'radio' && !groupRadioHasOneValue(value.elements, e.name)))
  )
}

/**
 *
 * Helpers
 *
 */

function simpleChoice(value, options) {
  return options.includes(value)
}

function multipleChoice(values, options) {
  return values.every(item => options.includes(item))
}

function multipleChoiceWithCustom(values, options) {
  return values.every(item => options.includes(item) || item)
}

function groupRadioHasOneValue(elements, groupName) {
  return (
    elements
      .filter(e => e.type === 'radio' && e.name === groupName)
      .reduce((total, elem) => total + elem.value, 0) === 1
  )
}

export function hasAppointment(value) {
  return value.start && value.end
}
