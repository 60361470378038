// this is an autogenerated file from App/Account/Content/Content/Home/MessageAlert/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Content_Home_MessageAlert_Content($user_ids: [String!]) {
  comms_threads(
    where: {
      status: { _eq: pending }
      resources_ids: { _has_keys_any: $user_ids }
    }
  ) {
    id
    status
    type
    name
    user_id
  }
}

`