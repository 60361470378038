// this is an autogenerated file from App/Account/Content/Content/Home/AppointmentBookings/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Content_Home_AppointmentBookings($person_id: numeric!) {
  vaxiom_appointments(
    where: {
      _or: [
        { patient: { person_id: { _eq: $person_id } } }
        {
          patient: {
            person: {
              to_person_relationships: { from_person: { _eq: $person_id } }
            }
          }
        }
      ]
      unplanned: { _eq: false }
    }
    order_by: [{ patient_id: asc }, { next_appointment_id: asc }]
  ) {
    id
    unplanned
    next_appointment_id
    patient {
      id
      person {
        id
        first_name
        last_name
      }
    }
    tx {
      id
      status
      name
      organization {
        id
        name
        lat
        lng
        time_zone_id
        postal_address {
          id
          address_line1
          address_line2
          state
          zip
        }
      }
    }
    type {
      id
      full_name
      is_virtual
    }
    remote_monitoring_requests(
      where: { status: { _eq: "created" } }
      limit: 1
    ) {
      id
      status
    }
    appointment_bookings(order_by: { id: desc }, limit: 1) {
      id
      appointment_id
      local_start_date
      local_start_time
      start_time
      duration
      video_link
      state
      confirmation_status
      chair {
        id
        resource {
          id
          organization {
            id
            name
            lat
            lng
            time_zone_id
            postal_address {
              id
              address_line1
              address_line2
              state
              zip
            }
          }
        }
      }
    }
  }
}

`