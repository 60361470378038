import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useSetFlowTo } from 'Simple/Flow.js'

export function useOnClick(props) {
  let user_id = useDataValue({
    viewPath: props.viewPath,
    context: 'thread_alert',
    path: 'user_id',
  })

  let setFlowTo = useSetFlowTo(props.viewPath)

  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'person_user_id',
  })

  return function onClick() {
    setFlowTo('/App/Account/Content/Content/Comms')
    change(user_id)
  }
}
