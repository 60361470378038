// this is an autogenerated file from App/Account/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account($id: numeric!, $user_id: uuid!) {
  patient_feature_flags_enabled {
    id
    appointments_cancel
    appointments_schedule
    appointments_reschedule
    comms_enabled
    login_enabled
    theme
  }

  users_by_pk(id: $user_id) {
    id
    vaxiom_person {
      id
      first_name
      primary_location_id
    }
  }

  vaxiom_persons(
    where: {
      to_person_relationships: {
        permitted_to_see_info: { _eq: true }
        from_person: { _eq: $id }
      }
    }
  ) {
    id
    first_name
    primary_location_id
    public_user {
      id
    }
  }
}

`