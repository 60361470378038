import { conformToMask } from 'text-mask-core'
import { login, logout } from 'Data/Auth.js'
import {
  INSTALLMENT_INTERVALS,
  MESSAGE_ACTIONS,
  THREAD_STATUSES,
} from './constants.js'
import intersectionBy from 'lodash/intersectionBy'
import differenceInCalendarYears from 'date-fns/differenceInCalendarYears'
import _formatDate from 'date-fns/format'
import _formatNumber from 'number-format.js'
import isValidDate from 'date-fns/isValid'
import padStart from 'lodash/padStart'
import parseDate from 'date-fns/parse'
import parseISO from 'date-fns/parseISO'
import formatISO from 'date-fns/formatISO'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import {
  patientHasGeneralDentistOptions,
  patientAntibioticsOptions,
  patientIsInPainOptions,
  patientProblemPreviousProcedureOptions,
  patientJawPainOptions,
  patientGeneralHealthOptions,
  patientDentalHealthOptions,
  patientLikeSmileOptions,
  patientGumsOptions,
  patientPeriodontalDiseaseOptions,
  patientToothbrushOptions,
  patientReactionsOptions,
  patientRelativesCrowdingOptions,
  patientClickingPainOptions,
  patientHasPersonalPhysicianOptions,
  patientActiveCareOptions,
  patientColdOptions,
  patientCancerMedicationsOptions,
  patientHabitsOptions,
  patientTobaccoOptions,
  patientBirthControlOptions,
  patientPregnantOptions,
  patientNursingOptions,
  patientMedicalConditionsOptions,
  patientGenderOptions,
} from 'Data/enums.js'
import toPascalCase from 'to-pascal-case'

export { logout, login }

export { toPascalCase }

function formatDate(rvalue, formatIn, formatOut) {
  let value =
    formatIn === 'iso'
      ? parseISO(rvalue)
      : parseDate(rvalue, formatIn, new Date())
  return isValidDate(value) ? _formatDate(value, formatOut) : rvalue
}

export function range(start, end, increment = 1) {
  let list = []
  for (let i = start; i <= end; i += increment) {
    list.push(i)
  }
  return list
}

export function nullToEmpty(value) {
  return !value ? '' : value
}

export function emptyToNull(value) {
  return value?.length === 0 ? null : value
}

export function questionText(name) {
  return `How likely would you recommend ${name} to your friend?`
}

export function formatNumber(value, format) {
  return typeof value === 'number' ? _formatNumber(format, value) : value
}

export function formatTime(hours, minutes, seconds = 0) {
  return `${formatNumber(hours, '0#')}:${formatNumber(
    minutes,
    '0#'
  )}:${formatNumber(seconds, '0#')}`
}

export function numberMoney(value) {
  return `$${numberThousand(value)}`
}

export function numberPercentage(value) {
  return `$${numberThousand(value)}`
}

export function address(address) {
  return `${address.street}, ${address.city}, ${address.state}, ${address.zip}`
}

export function addressPostal(rvalue) {
  let value =
    rvalue.appointment_bookings?.[0]?.chair?.resource?.organization ||
    rvalue.tx?.organization

  if (!value.postal_address) return value.name

  return `${value.name}, ${value.postal_address.address_line1}, ${
    value.postal_address.address_line2 !== null
      ? value.postal_address.address_line2 + ', '
      : ''
  }${value.postal_address.state}, ${value.postal_address.zip}`
}

export function age(date_of_birth) {
  return differenceInCalendarYears(new Date(), parseISO(date_of_birth)) || ''
}

export function fullname(value) {
  return `${value.firstname} ${value.lastname}`
}

let SOCIAL_SECURITY_NUMBER = /^\d{3}-\d{2}-\d{4}$/
export function socialSecurityNumber(input) {
  if (input && SOCIAL_SECURITY_NUMBER.test(input)) return input
  let normalizedValue = input.replace(/[^0-9]/g, '')
  if (normalizedValue.length === 9) {
    let tokens = [
      normalizedValue.slice(0, 3),
      normalizedValue.slice(3, 5),
      normalizedValue.slice(5, 9),
    ]
    return tokens.join('-')
  }
  return input
}
let SOCIAL_SECURITY_NUMBER_TO_HIDE = /^\d{3}-\d{2}/
export function socialSecurityNumberHide(value) {
  return value && value.replace(SOCIAL_SECURITY_NUMBER_TO_HIDE, '•••-••')
}

export function memberId(value) {
  return typeof value === 'string' ? padStart(value, 8, '0') : value
}

export function textToNumber(value) {
  return typeof value === 'string'
    ? parseFloat(value.replace(/[^0-9.]/g, ''), 10)
    : value
}

export function dateShortIn(value) {
  return formatDate(value, 'yyyy-MM-dd', 'MM/dd/yyyy')
}

export function dateShortInDots(value) {
  return formatDate(value, 'yyyy-MM-dd', 'MM.dd.yyyy')
}

export function dateShortInFull(value) {
  return formatDate(value, 'yyyy-MM-dd', 'EEEE, MMMM do yyyy')
}

export function dateShortOut(value) {
  return formatDate(value, 'MM/dd/yyyy', 'yyyy-MM-dd')
}

export function dateyyyyMMddToMMyyyy(value) {
  return formatDate(value, 'yyyy-MM-dd', 'MM/yyyy')
}

export function timeISOIn(value) {
  return formatDate(value, 'iso', 'h:mm a')
}

export function dateISOIn(value) {
  return formatDate(value, 'iso', 'MM/dd/yyyy')
}
export function dateISOOut(value) {
  return formatDate(value, 'MM/dd/yyyy', 'iso')
}

export function dateISOToeeeeMMMMdo(value) {
  return formatDate(value, 'iso', 'eeee MMMM do')
}

export function localDateToMMMdyyyy(value) {
  return isValidDate(value) ? _formatDate(value, 'MMM d, yyyy') : value
}

export function dateISOToTime(value) {
  return formatDate(value, 'iso', 'h:mma').toLowerCase()
}

export function localStartTime(value) {
  return _formatDate(parseISO('1970-01-01T' + value), 'h:mmaaa')
}

export function getTimeDisplay(value) {
  return _formatDate(parseISO(value.split('Z')[0]), 'h:mmaaa')
}

export function timestampISOIn(value) {
  return formatDate(value, 'iso', 'HH:mm MM/dd/yyyy z')
}

export function today() {
  return formatISO(Date.now(), { representation: 'date' })
}

export function getIsoTimeStringDisplay(value, military = false) {
  if (!value.includes('T')) return value

  let suffix = ''
  let rTime = value.split('T')[1].split('Z')[0]
  let [hours, mins] = rTime.split(':')

  if (!military) {
    suffix = 'am'
    if (hours >= 12) {
      suffix = 'pm'
    }
    if (hours > 12) {
      hours = hours - 12
    }
  }
  return `${hours}:${mins}${suffix}`
}

export function getIsoDateStringDisplay(value, format = 'MM/dd/yyyy') {
  if (!value.includes('T')) return value

  let rDate = value.split('T')[0]
  let [yyyy, mm, dd] = rDate.split('-')

  return format.replace('yyyy', yyyy).replace('MM', mm).replace('dd', dd)
}

let PHONE_NUMBER_US_MASK = [
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]
let DEFAULT_PHONE_CODE = '+1'
export function phoneNumberUS(rvalue) {
  if (!rvalue || !rvalue.startsWith(DEFAULT_PHONE_CODE)) return rvalue

  return conformToMask(
    rvalue.replace(DEFAULT_PHONE_CODE, ''),
    PHONE_NUMBER_US_MASK
  ).conformedValue
}

let PHONE_NUMBER_US_LAST_FOUR_MASK = [
  '(',
  '*',
  '*',
  '*',
  ')',
  ' ',
  '*',
  '*',
  '*',
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]
export function phoneNumberUSLastFour(rvalue) {
  if (!rvalue || !rvalue.startsWith(DEFAULT_PHONE_CODE)) return rvalue

  return conformToMask(
    rvalue.replace(DEFAULT_PHONE_CODE, ''),
    PHONE_NUMBER_US_LAST_FOUR_MASK
  ).conformedValue
}

export function booleanToYesNo(value) {
  if (value === null) return null
  return value ? 'Yes' : 'No'
}

export function numberThousand(value) {
  return formatNumber(value, '#,##0.00')
}

export function addressStreetZipCityState(value) {
  return value
    ? `${value.street}, ${value.zip}, ${value.city}, ${value.state}`
    : '-'
}

export function identity(value) {
  return value
}

export function getMedicalHistoryView(value) {
  return !value ? 'NoPatient' : !value.medical_history ? 'No' : 'Content'
}

export function getMedicalFormPatientLink(value) {
  return `${process.env.REACT_APP_URL_MEDICAL_FORM}/?patientId=${value}`
}

export function valueToPatientGeneralDentistText(value) {
  return findTextFromValue(value, patientHasGeneralDentistOptions)
}

export function valueToPatientAntibioticsText(value) {
  return findTextFromValue(value, patientAntibioticsOptions)
}

export function valueToPatientIsInPainText(value) {
  return findTextFromValue(value, patientIsInPainOptions)
}

export function valueToPatientFirstToothText(value) {
  return value ? value + ' months' : value
}

export function valueToPatientProblemPreviousProcedureText(value) {
  return findTextFromValue(value, patientProblemPreviousProcedureOptions)
}

export function valueToPatientJawPainText(value) {
  return findTextFromValue(value, patientJawPainOptions)
}

export function valueToGeneralHealthText(value) {
  return findTextFromValue(value, patientGeneralHealthOptions)
}

export function valueToDentalHealthText(value) {
  return findTextFromValue(value, patientDentalHealthOptions)
}

export function valueToPatientLikeSmileText(value) {
  return findTextFromValue(value, patientLikeSmileOptions)
}

export function valueToPatientGumsText(value) {
  return findTextFromValue(value, patientGumsOptions)
}

export function valueToPatientPeriodontalDiseaseText(value) {
  return findTextFromValue(value, patientPeriodontalDiseaseOptions)
}

export function valueToPatientToothbrushText(value) {
  return findTextFromValue(value, patientToothbrushOptions)
}

export function valueToPatientReactionsText(value) {
  return findTextFromValue(value, patientReactionsOptions)
}

export function valueToPatientRelativesCrowdingText(values) {
  return Array.isArray(values) && values.length > 0
    ? values
        .map(item =>
          findTextFromValue(
            item.patient_relative_crowding,
            patientRelativesCrowdingOptions
          )
        )
        .join(', ')
    : ''
}

export function valueToPatientClickingPainText(value) {
  return findTextFromValue(value, patientClickingPainOptions)
}

export function valueToPatientHasPersonalPhysicianText(value) {
  return findTextFromValue(value, patientHasPersonalPhysicianOptions)
}

export function valueToPatientActiveCareText(value) {
  return findTextFromValue(value, patientActiveCareOptions)
}

export function valueToPatientColdText(value) {
  return findTextFromValue(value, patientColdOptions)
}

export function valueToPatientCancerMedicationsText(value) {
  return findTextFromValue(value, patientCancerMedicationsOptions)
}

export function valueToPatientHabitsText(value) {
  return Object.keys(value)
    .filter(key => value[key] === true)
    .reduce((answer, key, index, array) => {
      return answer.concat(
        `${findTextFromValue(key, patientHabitsOptions)}${
          index < array.length - 1 ? ', ' : ''
        }`
      )
    }, '')
}

export function valueToPatientTobaccoText(value) {
  return findTextFromValue(value, patientTobaccoOptions)
}

export function valueToPatientBirthControlText(value) {
  return findTextFromValue(value, patientBirthControlOptions)
}

export function valueToPatientPregnantText(value) {
  return findTextFromValue(value, patientPregnantOptions)
}

export function valueToPatientNursingText(value) {
  return findTextFromValue(value, patientNursingOptions)
}

export function valueToPatientMedicalConditionsText(value) {
  return findTextFromValue(value, patientMedicalConditionsOptions)
}

export function valueToMedicationsText(values) {
  return Array.isArray(values) ? values.map(item => item.name).join(', ') : ''
}

export function valueToPatientAllergiesText(values) {
  return Array.isArray(values) ? values.map(item => item.name).join(', ') : ''
}

function findTextFromValue(value, array) {
  return array.find(obj => obj.value === value)?.text || null
}

export function creditCardOrBankAccount(value) {
  return `****${value}`
}

export function creditCardExpiration(value) {
  return value && `${value.substring(0, 2)}/${value.substring(2)}`
}

export function hasAppointment(value) {
  return value.start && value.end
}

export function datetimeISOIn(value) {
  return formatDate(value, 'iso', 'MM/dd/yyyy h:mm a')
}

export function appointmentDateTime(value) {
  return value.appointment.start === null
    ? null
    : `${formatDate(
        value.appointment.start,
        'iso',
        'MM/dd/yyyy'
      )} ${getTimeDisplay(value.appointment.start)}`
}

export function timeRange(start, end, increment = 1) {
  return range(start, end, increment).map(value => ({
    id: value,
    text: formatNumber(value, '0#'),
  }))
}

export function toSentence(value) {
  return Array.isArray(value)
    ? value.length === 0
      ? 'None'
      : value.join(', ')
    : null
}

export function patientName(value) {
  return `${value.person.first_name} ${value.person.last_name}`
}

/**
 * @param {{ first_name: string; last_name: string; greeting?: string }} value
 */
export function patientNameFull(value) {
  return [
    value.first_name,
    value.greeting && value.greeting !== value.first_name
      ? `"${value.greeting}"`
      : null,
    value.last_name,
  ]
    .filter(Boolean)
    .join(' ')
}

export function patientNameAndAmount(value) {
  return `${patientName(value.patient)} (${numberMoney(value.amount)})`
}

export function valueToPatientGenderText(value) {
  return findTextFromValue(value, patientGenderOptions)
}

export function patientHistoryAppointmentDate(value) {
  return `${dateShortInFull(
    value.appointment_bookings[0].local_start_date
  )} ${localStartTime(value.appointment_bookings[0].local_start_time)}`
}

export function makeGoogleLocationLink(rvalue) {
  let { address_line1, address_line2, state, zip } =
    rvalue.appointment_bookings?.[0]?.chair?.resource?.organization
      ?.postal_address || rvalue.tx?.organization?.postal_address

  return `https://www.google.com/maps/search/?api=1&query=${address_line1}+${
    address_line2 || ''
  }+${state}+${zip}+USA`
}

export function locationCoordinates(rvalue) {
  let value =
    rvalue.appointment_bookings?.[0]?.chair?.resource?.organization ||
    rvalue.tx?.organization
  return {
    lat: value?.lat,
    lng: value?.lng,
  }
}

export function appointmentRequestAppointmentDateTime(value) {
  return value.appointment.start === null
    ? null
    : datetimeISOIn(
        formatISO(
          utcToZonedTime(
            value.appointment.start,
            value.provider_location.timezone
          )
        )
      )
}

export function isNotEmptyList(list) {
  return Array.isArray(list) && list.length > 0
}

export function isListWithMoreThanOneItem(list) {
  return isNotEmptyList(list) && list.length > 1
}

export function isEmptyOrCancelled(value) {
  return (
    isEmptyList(value?.appointment_bookings) ||
    value?.appointment_bookings?.[0].state.endsWith('_CANCELLED')
  )
}

export function isEmptyList(list) {
  return Array.isArray(list) && list.length === 0
}

export function isNoShow(value) {
  return !!value?.state && value?.state === 'NO_SHOW'
}

export function isScheduled(value) {
  return !!value?.state && value.state === 'SCHEDULED'
}

export function isInProgress(value) {
  return !!value?.state && value.state === 'CHECKED_IN'
}

export function isAutoDraftTypeBankAccount(auto_draft_type) {
  return auto_draft_type === 'BANK_ACCOUNT'
}

export function isAutoDraftTypeCreditCard(auto_draft_type) {
  return auto_draft_type === 'CREDIT_CARD'
}

export function isBalanceDueNowGreaterThanZero(value) {
  return value && value.due_now && value.due_now > 0
}

export function isNotPublicRoleAndTokenValid(value) {
  return (
    value &&
    value.api_role !== 'public' &&
    value.access_token_data &&
    value.access_token_data.exp * 1000 > Date.now()
  )
}

export function anyPatientWithBalanceGreaterThanZero(value) {
  return Array.isArray(value) && value.some(item => item.balance > 0)
}

export function getInPersonOrVirtualView(value) {
  return value ? 'Virtual' : 'InPerson'
}

export function getSignatureRequestView(value) {
  return value ? 'Document' : 'Auth'
}

export function getSignaturePadView(value) {
  return value ? 'Edit' : 'Show'
}

export function getSignButtonText(value) {
  return value ? 'Edit' : 'Sign'
}

// Comms formatting

export function getMessageStatusView(value) {
  if (!value) return 'No'

  switch (value) {
    case THREAD_STATUSES.new:
      return 'OpenNew'
    case THREAD_STATUSES.open:
      return 'Open'
    case THREAD_STATUSES.onhold:
      return 'Onhold'
    case THREAD_STATUSES.closed:
      return 'Closed'
    case THREAD_STATUSES.pending:
      return 'Pending'
    default:
      return 'No'
  }
}

export function smsMessageStatus(value) {
  let STATUS_MAPPING = {
    accepted: 'Sending',
    queued: 'Sending',
    sending: 'Sending',
    sent: 'Delivered',
    delivered: 'Delivered',
    undelivered: 'Undelivered',
    failed: 'Failed',
  }

  return STATUS_MAPPING[value] || 'Sending'
}

export function relatedMembers(value) {
  return value.map(item => item.profile.first_name).join(', ')
}

export function threadName(thread) {
  return (
    thread.threads_mobile_numbers_inbounds
      .flatMap(item => item.mobile_numbers_inbound.vaxiom_persons)
      .filter(item => item?.vaxiom_person) //remove any potential empties
      .map(
        person =>
          `${person.vaxiom_person.first_name} ${person.vaxiom_person.last_name}`
      )
      .join(', ') ||
    `${phoneNumberUS(
      thread.threads_mobile_numbers_inbounds[0].mobile_numbers_inbound.number
    )} Ã¢â‚¬Â¢ No patient match`
  )
}

export function threadNameNoMatch(thread) {
  return (
    thread.threads_mobile_numbers_inbounds
      .flatMap(item => item.mobile_numbers_inbound.vaxiom_persons)
      .filter(item => item?.vaxiom_person) //remove any potential empties
      .map(
        person =>
          `${person.vaxiom_person.first_name} ${person.vaxiom_person.last_name}`
      )
      .join(', ') || `No patient match`
  )
}

export function statusActionDescription(compose_message, status) {
  return MESSAGE_ACTIONS[compose_message ? 'withMessage' : 'noMessage'][status]
}

export function statusActionName(status) {
  return MESSAGE_ACTIONS['tags'][status]
}

export function outboundNumber(outbound_number) {
  return `${outbound_number.name} ${phoneNumberUS(outbound_number.number)}`
}

export function selectedOutboundNumber(
  thread_outbound_numbers,
  all_outbound_numbers
) {
  return [
    ...intersectionBy(thread_outbound_numbers, all_outbound_numbers, 'id'),
    ...all_outbound_numbers,
    null,
  ][0]
}

export function threadNamePlaceholder(thread) {
  return `Message ${threadName(thread)}`
}

export function threadNumbers(thread) {
  return thread.threads_mobile_numbers_inbounds
    .map(item => phoneNumberUS(item.mobile_numbers_inbound.number))
    .join(', ')
}

export function threadOutBoundNumbers(thread) {
  return thread.threads_mobile_numbers_outbounds.map(item => ({
    id: item.mobile_numbers_outbound.id,
    number: item.mobile_numbers_outbound.number,
    name: item.mobile_numbers_outbound.name,
  }))
}

export function statusPersonName(person) {
  return person ? `${person.first_name} ${person.last_name}` : 'StatusBot'
}

export function personPhone(phone) {
  return phone.description
    ? `${phoneNumberUS(addPrefixIfMissing(phone.number))} (${
        phone.description
      })`
    : phoneNumberUS(addPrefixIfMissing(phone.number))
}

function isSelectedStatusX(list, x) {
  return list.includes(x)
}
function toggleSelectedStatusX(list, x) {
  // untoggle CLOSED if any other status is selected
  let statuses = list.filter(item => item !== CLOSED)
  return isSelectedStatusX(statuses, x)
    ? statuses.length === 1
      ? statuses
      : statuses.filter(item => item !== x)
    : [...statuses, x]
}

let OPEN = 'open'
export function isSelectedStatusOpen(value) {
  return isSelectedStatusX(value, OPEN)
}
export function toggleSelectedStatusOpen(value, data) {
  return toggleSelectedStatusX(data.statuses, OPEN)
}

let CLOSED = 'closed'
export function isSelectedStatusClosed(value) {
  return isSelectedStatusX(value, CLOSED)
}
export function toggleSelectedStatusClosed(value, data) {
  return isSelectedStatusClosed(data.statuses) ? [OPEN] : [CLOSED]
}

let ONHOLD = 'onhold'
export function isSelectedStatusOnHold(value) {
  return isSelectedStatusX(value, ONHOLD)
}
export function toggleSelectedStatusOnHold(value, data) {
  return toggleSelectedStatusX(data.statuses, ONHOLD)
}

let PENDING = 'pending'
export function isSelectedStatusPending(value) {
  return isSelectedStatusX(value, PENDING)
}
export function toggleSelectedStatusPending(value, data) {
  return toggleSelectedStatusX(data.statuses, PENDING)
}

export function addPrefixIfMissing(number) {
  let result = (number || '').replace(/[^0-9+]/g, '')
  if (!result || result.startsWith('+1')) {
    return result
  } else if (result.startsWith('1') && result.length > 10) {
    return `+${result}`
  } else return `+1${result}`
}

export function unreadThreadsCount(value) {
  return `New (${value})`
}

export function personName(value) {
  if (!value) return ''

  let role = ''
  if (value.is_patient) {
    role = '(Patient)'
  } else if (value.is_payer) {
    role = '(Payer)'
  } else if (value.is_employee) {
    role = '(Employee)'
  } else if (value.is_external_professional) {
    role = '(External professional)'
  }
  return `${value.first_name} ${value.last_name} ${role}`
}

export function isInApp(type) {
  return type === 'in_app'
}

export function treatmentSummary(value) {
  return value.length_in_months > 0
    ? `${value.name}, ${value.length_in_months} ${
        value.length_in_months > 1 ? 'months' : 'month'
      }`
    : value.name
}

export function paymentPlanDescription(value) {
  return value.is_monthly ? `${value.length} monthly payments` : 'Paid-In-Full'
}

export function paymentPlanLength(value) {
  return `${value} ${value === 1 ? 'month' : 'months'}`
}

export function paymentFrequencyLabel(value) {
  let frequency = INSTALLMENT_INTERVALS[value]
  return frequency ? frequency.label : value
}

export function not(value) {
  return !value
}

export function toPositive(value) {
  return Math.abs(value)
}

export function getPaymentPlanView(value) {
  return value?.length ? 'Options' : 'Custom'
}

export function roundTwoDecimalsDown(value) {
  return Math.floor(value * 100) / 100
}

export function textWithLinks(value) {
  if (!hasUrls(value)) return value

  return (
    <div
      className="raw-html"
      dangerouslySetInnerHTML={{ __html: `${replaceUrlsWithLinks(value)}` }}
    />
  )
}

export function stripHtml(text) {
  if (text === null) return ''

  let parser = new DOMParser()
  let doc = parser.parseFromString(text, 'text/html')
  return doc.body.textContent || ''
}

export function textWithHTML(value) {
  if (!hasHTML(value)) return textWithLinks(value)

  return (
    <div
      className="raw-html"
      dangerouslySetInnerHTML={{ __html: `${value}` }}
    />
  )
}

function hasUrls(text) {
  let urlPattern = /(https?:\/\/\S+)/gi
  return urlPattern.test(text)
}

export function hasHTML(text) {
  let urlPattern = /<[a-z][\s\S]*>/gi
  return urlPattern.test(text)
}

/** @type {(text: string) => string} */
export function replaceUrlsWithLinks(text) {
  let urlPattern = /(https?:\/\/\S+)/gi

  /** @type {(text: string) => string} */
  function replaceUrl(match) {
    let url = match.trim()
    let trailingPunctuation = ''
    // If the match ends with . , ? or ! or ), etc., strip it
    let punctuationRegex = /[.,!?)]$/
    if (punctuationRegex.test(url)) {
      trailingPunctuation = url.slice(-1)
      url = url.slice(0, -1)
    }
    let sanitizedUrl = url.replace(/</g, '&lt;').replace(/>/g, '&gt;')

    return `<a href="${sanitizedUrl}" target="_blank">${sanitizedUrl}</a>${trailingPunctuation}`
  }

  // Create a new DOMParser instance
  let parser = new DOMParser()

  // Parse the text as HTML and retrieve the text content
  let strippedText = parser.parseFromString(text, 'text/html').documentElement
    .textContent

  // Replace the URLs with links in the stripped text
  let replacedText = strippedText?.replace(urlPattern, replaceUrl)

  // Return the replaced text
  return replacedText ?? text
}

export function capitalize(value) {
  if (!value || typeof value !== 'string') return ''
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}

export function initials(value) {
  if (!value) return ''

  return `${value.first_name.slice(0, 1)}${value.last_name.slice(0, 1)}`
}

export function percentage(value) {
  return value === 0 ? '0%' : formatNumber(value || 0, '###.##%')
}
