import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    if (!data.patient_feature_flags_enabled.login_enabled) return null

    return {
      feature_flags: { ...data.patient_feature_flags_enabled },
      user: data.users_by_pk,
      persons: data.vaxiom_persons,
    }
  }, [data])
}
